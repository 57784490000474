/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-trailing-spaces */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '../../../components/CustomButtons/Button';
import CustomInput from '../../../components/CustomInput/CustomInput';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';

const EditUserModal = ({
  open, togalModal, group, history, allCompanies,
}) => {
  const [groupName, setGroupName] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [companies, setCompanies] = useState([]);
  const [companyToAdd, setCompanyToAdd] = useState('');
  const [companyToAddId, setCompanyToAddId] = useState(0);

  const context = useContext(AuthContext);

  useEffect(() => {
    if (group.name) {
      setGroupName(group.name);
      // setGroupId(group.id);
      setContactEmail(group.contactEmail);
      setCompanies(group.companies);
    }
  }, [group]);

  const handleSubmit = () => {
    axios({
      method: 'patch',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/training-groups/${group.id}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: { 
        name: groupName,
        contactEmail,
      },
    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully edited Group: ${groupName}`);
      togalModal();
    }).catch((err) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${err.response.data.message}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        history.push('auth/login');
      }
    });
  };

  const handleSelectCompany = (e, child) => {
    setCompanyToAdd(e.target.value);
    setCompanyToAddId(child.props.companyId);
  };

  const handleAddCompany = () => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/add-company-to-training-group?trainingGroupId=${group.id}&companyId=${companyToAddId}`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },

    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully Added Company: ${companyToAdd}`);
      togalModal();
    }).catch((err) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${err.response.data.message}`);
      if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
        history.push('auth/login');
      }
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => togalModal()}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">Training Group</DialogTitle>
        <DialogContent>
          {groupName.length === 0 ? (
            <p style={{ color: 'red' }}>Must have a name!</p>
          ) : null}
          <CustomInput
            error={groupName.length === 0}
            labelText={(
              <span>
                Training Group Name
                {' '}
                <small>(required)</small>
              </span>
            )}
            id="groupName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: groupName,
              onChange: (event) => setGroupName(event.target.value),
            }}
          />
          
          <CustomInput
            error={contactEmail && contactEmail.length === 0}
            labelText={(
              <span>
                Contact Email Address
              </span>
            )}
            id="email"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: contactEmail,
              onChange: (event) => setContactEmail(event.target.value),
            }}
          />
          <div>
            <h4>Companies assigned to Training Group:</h4>
            {companies.map((company) => (
              <p>{company.name}</p>
            ))}
            <div>
              Add Company:
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">Company</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={companyToAdd}
                  label="companyToAdd"
                  onChange={(e, child) => handleSelectCompany(e, child)}
                >
                  {allCompanies.map((company) => (
                    <MenuItem
                      key={company.id}
                      value={company.id}
                      companyId={company.companies[0] ? company.companies[0].id : 0}
                    >
                      {company.name}
                    </MenuItem>
                  ))}
                </Select>
              
              </FormControl>
              <Button onClick={() => handleAddCompany()}>Add Company</Button>
            </div>

          </div>

        </DialogContent>
        <DialogActions>
          <Button onClick={() => togalModal()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()}>
            Edit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ); 
};

EditUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  togalModal: PropTypes.func.isRequired,
  history: PropTypes.object,
  group: PropTypes.object.isRequired,
  allCompanies: PropTypes.array,
};

export default EditUserModal;

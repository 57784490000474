/* eslint-disable react/no-array-index-key */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable no-trailing-spaces */
import React, { useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '../../../components/CustomButtons/Button';
import CustomInput from '../../../components/CustomInput/CustomInput';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';

const EditUserModal = ({
  open, togalModal, 
//   history,
}) => {
  const [groupName, setGroupName] = useState('');

  const context = useContext(AuthContext);

  const handleSubmit = () => {
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/training-groups`,
      headers: {
        Authorization: `bearer ${loadAuthToken()}`,
      },
      data: { 
        name: groupName,
      },
    }).then(() => {
      context.setMessageState('success');
      context.setSnackBarMessage(`Successfully edited Group: ${groupName}`);
      togalModal();
    }).catch((err) => {
      context.setMessageState('error');
      context.setSnackBarMessage(`${err.response.data.message}`);
    //   if (err.error === 'your token is not valid' || err.error === 'Your token is expired') {
    //     history.push('auth/login');
    //   }
    });
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={() => togalModal()}
        aria-labelledby="form-dialog-title"
        PaperProps={{
          style: {
            width: '100%',
            minHeight: '60%',
          },
        }}
      >
        <DialogTitle id="form-dialog-title">New Training Group</DialogTitle>
        <DialogContent>
          <CustomInput
            labelText={(
              <span>
                Training Group Name
                {' '}
                <small>(required)</small>
              </span>
            )}
            id="groupName"
            formControlProps={{
              fullWidth: true,
            }}
            inputProps={{
              value: groupName,
              onChange: (event) => setGroupName(event.target.value),
            }}
          />

        </DialogContent>
        <DialogActions>
          <Button onClick={() => togalModal()} color="primary">
            Cancel
          </Button>
          <Button onClick={() => handleSubmit()}>
            Create
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  ); 
};

EditUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  togalModal: PropTypes.func.isRequired,
  history: PropTypes.object,
};

export default EditUserModal;

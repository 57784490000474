/* eslint-disable react/forbid-prop-types */
import React, { useState, useContext, useEffect } from 'react';
import axios from 'axios';
import { PulseLoader } from 'react-spinners';
import { makeStyles } from '@material-ui/core/styles';
import Card from '../../../components/Card/Card';
import CardFooter from '../../../components/Card/CardFooter';
import CardBody from '../../../components/Card/CardBody';
import CardHeader from '../../../components/Card/CardHeader';
import styles from '../../../assets/jss/material-dashboard-pro-react/views/reportPageStyle';
import TrainingTableGrid from './TrainingGroupTable';
import NewTrainingGroupModal from './NewTraininGroupModal';
import Button from '../../../components/CustomButtons/Button';
import { AuthContext } from '../../../Auth';
import { loadAuthToken } from '../../../utils/auth';

const useStyles = makeStyles(styles);

function UsersTable() {
  const classes = useStyles();
  const context = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [toggalNewTrainingModal, setToggalNewTrainingModal] = useState(false);

  const fetchData = async () => {
    setIsLoading(!isLoading);
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_AUTH_SERVICE}/training-groups`,
      headers: {
        Authorization: loadAuthToken(),
      },
    }).then((res) => {
      setData(res.data.data);
      setIsLoading(false);
      return res;
    })
      .catch((e) => {
        console.log(e);
        context.setMessageState('error');
        context.setSnackBarMessage(`${e.response.data.message}`);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);// eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Card style={{ minHeight: '80vh' }}>
      <CardHeader className={classes.headerAlign}>
        <div className={classes.header}>
          <h3>Traning Groups</h3>
        </div>
        <div>
          <NewTrainingGroupModal open={toggalNewTrainingModal} togalModal={setToggalNewTrainingModal} />

          <Button
            variant="contained"
            color="primary"
            onClick={(e) => {
              e.preventDefault();
              setToggalNewTrainingModal(!toggalNewTrainingModal);
            }}
          >
            New Training Group
          </Button>
        </div>

      </CardHeader>
      <CardBody className={classes.reportCardBody}>
        {isLoading ? (
          <div style={{ margin: 'auto' }}>
            <PulseLoader
              sizeUnit="px"
              size={15}
              color="#6CCA98"
              loading
            />
          </div>
        ) : <TrainingTableGrid data={data} fetchData={fetchData} />}
      </CardBody>

      <CardFooter stats>
        <div className={classes.stats}>
          <p className={classes.cardTitle}>
            Total:
            {data.length}
          </p>
        </div>
      </CardFooter>
    </Card>
  );
}

export default UsersTable;
